import { useEffect, useState } from 'react';
import { FaqItem } from 'infrastructure/gatsby/types/faq-item';

type UseFaqSearchProps = {
  allEntries: FaqItem[];
  activeCategory?: string;
  forceHeightRecalculation: boolean;
  setForceHeightRecalculation: React.Dispatch<React.SetStateAction<boolean>>;
}

export type UseFaqSearchResult = {
  searchedValue: string;
  handleSearchedValueChange: (value: string) => void;
  filteredEntries: FaqItem[];
  activePanelIndices: number[] | null;
  searchValueRegx: RegExp;
}

const escapeRegExp = (
  str: string,
): string => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const useFaqSearch = (
  props: UseFaqSearchProps,
): UseFaqSearchResult => {
  const [searchedValue, setSearchedValue] = useState('');

  const handleSearchedValueChange = (value: string) => {
    setSearchedValue(value);
  };

  const filteredEntries = props.allEntries.filter(
    (entry) => (
      entry
        .title
        .toLowerCase()
        .includes(searchedValue.toLowerCase())
        || entry
          .description
        ?.toLowerCase()
        .includes(searchedValue.toLowerCase())
    ),
  );

  const activePanelIndices = searchedValue === ''
    ? null
    : filteredEntries.map((_, index) => index);

  useEffect(() => {
    handleSearchedValueChange('');
  }, [props.activeCategory]);

  useEffect(() => {
    props.setForceHeightRecalculation(!props.forceHeightRecalculation);
  }, [filteredEntries.length]);

  const searchValueRegx = new RegExp(escapeRegExp(searchedValue), 'gi');

  return {
    searchedValue,
    handleSearchedValueChange,
    filteredEntries,
    activePanelIndices,
    searchValueRegx,
  };
};

export default useFaqSearch;
