type KeyValuePair = {[param: string]: string};

export const parseQueryString = (query: string): KeyValuePair => {
  const params: KeyValuePair = {};

  const pairs = (
    query[0] === '?'
      ? query.substr(1)
      : query
  ).split('&');

  pairs.forEach((pair) => {
    const tupple = pair.split('=');

    params[decodeURIComponent(tupple[0])] = decodeURIComponent(tupple[1] || '');
  });

  return params;
};
