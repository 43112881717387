import React from 'react';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import FormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral'; // eslint-disable-line max-len
import InputTheme
  from '@shipae/components-sandbox/component/input/theme/neutral'; // eslint-disable-line max-len
import styled from 'styled-components';
import { useTranslation } from 'src/views/misc/localization';
import { UseFaqSearchResult } from './use-faq-search';

type Props = Pick<
  UseFaqSearchResult,
  'searchedValue' | 'handleSearchedValueChange'
>;

const CustomFormElementTheme = {
  ...FormElementTheme,
  Main: styled(FormElementTheme.Main)`
    margin: 9rem 0;
  `,
};

const FaqSearch: React.FC<Props> = ({
  searchedValue,
  handleSearchedValueChange,
}) => {
  const { t } = useTranslation();

  return (
    <FormElement
      theme={CustomFormElementTheme}
      label={t('searchFaq')}
      hasValue={!!searchedValue}
    >
      <Input
        theme={InputTheme}
        type="text"
        value={searchedValue}
        onChange={handleSearchedValueChange}
      />
    </FormElement>
  );
};

export default FaqSearch;
