import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';

import { faqIdsAreEqual } from 'src/util';
import { parseQueryString } from 'src/views/utils/parse-query-string';
import TabPanel from '@shipae/components-sandbox/component/tabs/panel';
import MinimalAccordion
  from '@shipae/components-sandbox/component/minimal-accordion';
import ReactMarkdown from 'markdown-to-jsx';
import RichText from '@shipae/components-sandbox/component/rich-text';
import { FaqItem } from 'infrastructure/gatsby/types/faq-item';
import { accordionTheme } from '../styles';
import useFaqSearch from '../use-faq-search';
import FaqSearch from '../search';
import { Highlight } from '../highlight';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

type Props = {
  faqs: FaqItem[],
  category: string,
  forceHeightRecalculation: boolean,
  activeCategory: string;
  setForceHeightRecalculation: React.Dispatch<React.SetStateAction<boolean>>;
};

const Panel: React.FC<Props> = ({
  faqs,
  category,
  forceHeightRecalculation,
  activeCategory,
  setForceHeightRecalculation,
}) => {
  const [activeFaqIndex, setActiveFaqIndex] = useState(-1);
  const [activePanel, setActivePanel] = useState(0);
  const [scrollToPanel, setScrollToPanel] = useState(-1);

  const location = useLocation();
  const query = (location && parseQueryString(location.search)) || {};
  const { faqid } = query;

  useEffect(() => {
    const index = faqs.findIndex(
      (faq: FaqItem) => faqIdsAreEqual(faq.id, faqid),
    );
    setActivePanel(index < 0 ? 0 : index);
    setActiveFaqIndex(index);
  }, []);

  useEffect(() => {
    if (activeFaqIndex > -1) {
      setScrollToPanel(activeFaqIndex);
    }
  }, [activeFaqIndex]);

  const {
    searchedValue,
    handleSearchedValueChange,
    filteredEntries,
    activePanelIndices,
    searchValueRegx,
  } = useFaqSearch({
    allEntries: faqs,
    activeCategory,
    forceHeightRecalculation,
    setForceHeightRecalculation,
  });

  return (
    <TabPanel id={category}>
      <FaqSearch
        searchedValue={searchedValue}
        handleSearchedValueChange={handleSearchedValueChange}
      />
      <MinimalAccordion
        multipleActivePanels={activePanelIndices}
        entries={filteredEntries?.length > 0
          ? filteredEntries.map((faq: FaqItem) => ({
            title: searchedValue
              ? (
                <ReactMarkdown
                  options={{
                    ...gloabalReactMdOptions,
                    forceBlock: false,
                    overrides: {
                      Highlight: {
                        component: Highlight,
                      },
                    },
                  }}
                >
                  {faq?.title?.replace(
                    searchValueRegx,
                    (match) => `<Highlight>${ match }</Highlight>`,
                  ) || ''}
                </ReactMarkdown>
              )
              : faq.title,
            content: (
              <RichText>
                <ReactMarkdown
                  options={{
                    ...gloabalReactMdOptions,
                    forceBlock: false,
                    overrides: {
                      Highlight: {
                        component: Highlight,
                      },
                    },
                  }}
                >
                  {searchedValue ? faq?.description?.replace(
                    searchValueRegx,
                    (match) => `<Highlight>${ match }</Highlight>`,
                  ) || '' : faq?.description || ''}
                </ReactMarkdown>
              </RichText>
            ),
          }))
          : []}
        activePanel={activePanel}
        scrollToPanel={scrollToPanel}
        forceHeightRecalculation={forceHeightRecalculation}
        theme={accordionTheme}
      />
    </TabPanel>
  );
};

export default Panel;
