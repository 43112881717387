// transform string to lowercase separated with '-' dash
export const kebabCase = (st: string): string => {
  const res = st.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
  return res;
};

export const faqIdsAreEqual = (id1: string, id2: string): boolean => {
  const regex = /[0-9]+$/;
  const m1 = id1?.match(regex);
  const m2 = id2?.match(regex);

  return !!m1 && !!m2 && m1[0] === m2[0];
};
