import React, { useState } from 'react';
import { useLocation } from '@reach/router';

import { parseQueryString } from 'src/views/utils/parse-query-string';
import { FaqTab } from 'infrastructure/gatsby/types/faq-list';
import { FaqItem } from 'infrastructure/gatsby/types/faq-item';
import { useTranslation } from 'src/views/misc/localization';
import { useFaqs } from 'src/infrastructure/hooks/use-faqs';
import {
  useFaqsTranslations,
} from 'src/infrastructure/hooks/use-faqs-translations';
import Tabs from '@shipae/components-sandbox/component/tabs';
import Tab from '@shipae/components-sandbox/component/tabs/tab';
import Panel from './panel';

type Props = {
  faqData: FaqTab[],
};
const WithTabs: React.FC<Props> = ({ faqData }) => {
  const location = useLocation();
  const query = (location && parseQueryString(location.search)) || {};
  const { faqcategory } = query;

  const [activeCategory, setActiveCategory] = useState(
    faqcategory || faqData[0].category.toLowerCase(),
  );
  const [forceHeightRecalculation, setForceHeightRecalculation] = useState(
    false,
  );

  const { locale } = useTranslation();
  const faqsEnglish = useFaqs();
  const faqsArabic = useFaqsTranslations();
  const allFaqs = locale === 'ar' ? faqsArabic : faqsEnglish;

  const tabs = faqData.map((tab: FaqTab) => {
    const faqs = allFaqs.filter((
      faq: FaqItem,
    ) => faq.category.toLowerCase() === tab.category.toLowerCase());

    return { ...tab, faqs };
  });

  const onTabChange = (category: string) => {
    setForceHeightRecalculation(!forceHeightRecalculation);
    setActiveCategory(category.toLowerCase());
  };

  return (
    <Tabs
      onChange={onTabChange}
      panels={(
        <>
          {tabs.map((tab) => (
            <Panel
              key={tab.category.toLowerCase()}
              faqs={tab.faqs}
              category={tab.category.toLowerCase()}
              forceHeightRecalculation={forceHeightRecalculation}
              activeCategory={activeCategory}
              setForceHeightRecalculation={setForceHeightRecalculation}
            />
          ))}
        </>
      )}
    >
      {tabs.map((tab) => (
        <Tab
          id={tab.category.toLowerCase()}
          key={tab.category.toLowerCase()}
          active={tab.category.toLowerCase() === activeCategory}
        >
          {tab.title}
        </Tab>
      ))}
    </Tabs>
  );
};

export default WithTabs;
