import React, { useContext } from 'react';

import Breadcrumbs from '@shipae/components-sandbox/component/breadcrumbs';

import { FaqList } from 'infrastructure/gatsby/types/faq-list';
import { calculateBreadcrumbs } from 'views/utils/calculate-breadcrumbs';
import { PageContext } from 'views/templates/context';
import { useTranslation } from 'src/views/misc/localization';
import NoTabs from './no-tabs';
import WithTabs from './with-tabs';
import {
  Section, Title, Content, breadcrumbsTheme,
} from './styles';
import { ROUTES } from '../../constants/ROUTES';

const FaqListSection: React.FC<FaqList> = ({
  sectionId,
  title,
  showBreadcrumbs,
  faqsTabs,
}) => {
  const { locale, t } = useTranslation();
  const pageContext = useContext(PageContext);

  return (
    <Section id={sectionId}>
      {showBreadcrumbs
        ? (
          <Breadcrumbs
            home={{
              label: t('home'),
              url: ROUTES[locale].HOME,
            }}
            links={calculateBreadcrumbs({
              parentTitle: pageContext.pageSettings.parentPageTitle,
              parentPath: pageContext.pageSettings.parentPagePath,
              title: pageContext.title,
            })}
            theme={breadcrumbsTheme}
          />
        ) : null}
      <Title>
        {title}
      </Title>
      <Content>
        {(!faqsTabs || faqsTabs.length <= 0) && t('faqListSectionNoItems')}
        {faqsTabs && faqsTabs.length === 1 && <NoTabs faqData={faqsTabs[0]} />}
        {faqsTabs && faqsTabs.length > 1 && <WithTabs faqData={faqsTabs} />}
      </Content>
    </Section>
  );
};

export default FaqListSection;
