import styled from 'styled-components';
import BreadcrumbsTheme
  from '@shipae/components-sandbox/component/breadcrumbs/theme';
import MinimalAccordionTheme
  from '@shipae/components-sandbox/component/minimal-accordion/theme';

import { fonts, media } from 'views/theme';

export const Section = styled.section`
  padding: 11.8rem calc((100% - var(--container-width)) / 2);
`;

export const Title = styled.h1`
  margin-bottom: 10rem;
  ${ fonts.displaym200 }
`;

export const Content = styled.div`
  margin: 0 24rem;

  ${ media.max(1600) } {
    margin: 0 12rem;
  }

  ${ media.md } {
    margin: 0 6rem;
  }

  ${ media.sm } {
    margin: 0 3rem;
  }
`;

export const breadcrumbsTheme = {
  ...BreadcrumbsTheme,
  Main: styled(BreadcrumbsTheme.Main)`
    margin-bottom: 1.2rem;
  `,
};

export const accordionTheme = {
  ...MinimalAccordionTheme,
  Head: styled(MinimalAccordionTheme.Head).attrs({ as: 'h2' })``,
};
