import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';

import { parseQueryString } from 'src/views/utils/parse-query-string';
import RichText from '@shipae/components-sandbox/component/rich-text';
import ReactMarkdown from 'markdown-to-jsx';
import MinimalAccordion
  from '@shipae/components-sandbox/component/minimal-accordion';
import { useTranslation } from 'src/views/misc/localization';
import { FaqItem } from 'infrastructure/gatsby/types/faq-item';
import { FaqTab } from 'infrastructure/gatsby/types/faq-list';
import { useFaqs } from 'src/infrastructure/hooks/use-faqs';
import {
  useFaqsTranslations,
} from 'src/infrastructure/hooks/use-faqs-translations';
import { faqIdsAreEqual } from 'src/util';
import { accordionTheme } from '../styles';
import useFaqSearch from '../use-faq-search';
import FaqSearch from '../search';
import { Highlight } from '../highlight';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

type Props = {
  faqData: FaqTab,
};
const NoTabs: React.FC<Props> = ({ faqData }) => {
  const [activeFaqIndex, setActiveFaqIndex] = useState(-1);
  const [activePanel, setActivePanel] = useState(0);
  const [scrollToPanel, setScrollToPanel] = useState(-1);
  const location = useLocation();
  const query = (location && parseQueryString(location.search)) || {};
  const { faqid } = query;

  const { category } = faqData;
  const { locale } = useTranslation();
  const faqsEnglish = useFaqs();
  const faqsArabic = useFaqsTranslations();
  const allFaqs = locale === 'ar' ? faqsArabic : faqsEnglish;
  const faqs = allFaqs.filter((faq: FaqItem) => faq.category === category);

  useEffect(() => {
    const index = faqs.findIndex(
      (faq: FaqItem) => faqIdsAreEqual(faq.id, faqid),
    );
    setActivePanel(index < 0 ? 0 : index);
    setActiveFaqIndex(index);
  }, []);

  useEffect(() => {
    if (activeFaqIndex > -1) {
      setScrollToPanel(activeFaqIndex);
    }
  }, [activeFaqIndex]);

  const [forceHeightRecalculation, setForceHeightRecalculation] = useState(
    false,
  );

  const {
    searchedValue,
    handleSearchedValueChange,
    filteredEntries,
    activePanelIndices,
    searchValueRegx,
  } = useFaqSearch({
    allEntries: faqs,
    forceHeightRecalculation,
    setForceHeightRecalculation,
  });

  return (
    <>
      <FaqSearch
        searchedValue={searchedValue}
        handleSearchedValueChange={handleSearchedValueChange}
      />
      <MinimalAccordion
        multipleActivePanels={activePanelIndices}
        entries={filteredEntries && filteredEntries.length > 0
          ? filteredEntries.map((faq: FaqItem) => ({
            title: searchedValue
              ? (
                <ReactMarkdown
                  options={{
                    ...gloabalReactMdOptions,
                    forceBlock: false,
                    overrides: {
                      Highlight: {
                        component: Highlight,
                      },
                    },
                  }}
                >
                  {faq?.title?.replace(
                  searchValueRegx,
                  (match) => `<Highlight>${ match }</Highlight>`,
                ) || ''}
                </ReactMarkdown>
              )
              : faq.title,
            content: (
              <RichText>
                <ReactMarkdown
                  options={{
                    ...gloabalReactMdOptions,
                    forceBlock: false,
                    overrides: {
                      Highlight: {
                        component: Highlight,
                      },
                    },
                  }}
                >
                  {searchedValue ? faq?.description?.replace(
                    searchValueRegx,
                    (match) => `<Highlight>${ match }</Highlight>`,
                  ) || '' : faq?.description || ''}
                </ReactMarkdown>
              </RichText>
            ),
          }))
          : []}
        forceHeightRecalculation={forceHeightRecalculation}
        activePanel={activePanel}
        scrollToPanel={scrollToPanel}
        theme={accordionTheme}
      />
    </>
  );
};
export default NoTabs;
